// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../InterCard/img/interBG.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../InterCard/img/inter__PL.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../InterCard/img/Group 14509.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tovar_section__tovar__p4uZw {
  padding: 100px 0px 80px 0px;
  min-height: 80vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
@media screen and (max-width: 990px) {
  .Tovar_section__tovar__p4uZw {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  }
}
@media screen and (max-width: 550px) {
  .Tovar_section__tovar__p4uZw {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    background-position: right;
  }
}

.Tovar_tovar__N42fP {
  display: flex;
  align-items: first baseline;
  justify-content: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}
@media screen and (max-width: 1420px) {
  .Tovar_tovar__N42fP {
    gap: 25px;
  }
}
@media screen and (max-width: 1100px) {
  .Tovar_tovar__N42fP {
    justify-content: flex-start;
  }
}
@media screen and (max-width: 480px) {
  .Tovar_tovar__N42fP {
    gap: 40px;
  }
}
.Tovar_tovar__text__\\+ujkE {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/pages/Tovar/Tovar.module.scss"],"names":[],"mappings":"AAAA;EAEI,2BAAA;EACA,gBAAA;EAGA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;AAFJ;AAII;EAXJ;IAYQ,yDAAA;EADN;AACF;AAGI;EAfJ;IAgBQ,yDAAA;IACJ,0BAAA;EAAF;AACF;;AAKA;EAEI,aAAA;EACA,2BAAA;EAEA,2BAAA;EACA,SAAA;EAEA,eAAA;AALJ;AAOI;EAVJ;IAWQ,SAAA;EAJN;AACF;AAMI;EAdJ;IAeQ,2BAAA;EAHN;AACF;AAKI;EAlBJ;IAmBQ,SAAA;EAFN;AACF;AAII;EACI,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AAFR","sourcesContent":[".section__tovar{\n\n    padding: 100px 0px 80px 0px;\n    min-height: 80vh;\n    \n\n    background-image: url('../InterCard/img/interBG.png');\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: left;\n\n    @media screen and (max-width:  990px ){\n        background-image: url('../InterCard/img/inter__PL.png');\n    }\n\n    @media screen and (max-width:  550px ){\n        background-image: url('../InterCard/img/Group\\ 14509.png');\n    background-position: right;\n\n    }\n    \n}\n\n.tovar{\n    \n    display: flex;\n    align-items:first baseline;\n    // justify-content: space-between;\n    justify-content: flex-start;\n    gap: 30px;\n\n    flex-wrap: wrap;\n\n    @media screen and (max-width: 1420px ) {\n        gap: 25px;\n    }\n\n    @media screen and (max-width: 1100px ) {\n        justify-content: flex-start;  \n    }\n\n    @media screen and (max-width: 480px ) {\n        gap: 40px;\n    }\n\n    &__text{\n        text-transform: uppercase;\n        font-size: 15px;\n        font-weight: 600;\n        color: #000;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section__tovar": `Tovar_section__tovar__p4uZw`,
	"tovar": `Tovar_tovar__N42fP`,
	"tovar__text": `Tovar_tovar__text__+ujkE`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.dropdown-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
}

.dropdown-container.open {
  background: #303030;
  border-radius: 4px;
}

.dropdown-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dfdfdf;
  font-size: 16px;
  width: 120px;
  gap: 2px;
  height: 100%;
  padding: 2%;
}

.arrow-icon {
  transition: transform 0.5s;
  width: 100%;
  height: 16px;
}

.options-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 120%;
  left: -6px;
  width: 100%;
  background: #303030;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 440px;
  overflow-y: auto;
  color: #dfdfdf;
}

/* Стили для опции */
.options-list li {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.5s;
}

.options-list li:hover {
  background-color: #1c1c1c;
  color: #FFEA2D;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Select.moduls.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,kBAAA;EACA,qBAAA;EACA,eAAA;EACA,oBAAA;AAEJ;;AAIA;EACI,mBAAA;EACA,kBAAA;AADJ;;AAMA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;EACA,QAAA;EACA,YAAA;EACA,WAAA;AAHJ;;AAOA;EACI,0BAAA;EACA,WAAA;EACA,YAAA;AAJJ;;AAUA;EACI,qBAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;EACA,gBAAA;EACA,0BAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAPJ;;AAUA,oBAAA;AACA;EACI,YAAA;EACA,eAAA;EACA,iCAAA;AAPJ;;AAYA;EACI,yBAAA;EACA,cAAA;AATJ","sourcesContent":[".dropdown-container {\n    position: relative;\n    display: inline-block;\n    cursor: pointer;\n    transition: all 0.3s;\n    \n    \n}\n\n\n.dropdown-container.open {\n    background: #303030;\n    border-radius: 4px;\n  \n}\n\n\n.dropdown-header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #dfdfdf;\n    font-size: 16px;\n    width: 120px;\n    gap: 2px;\n    height: 100%;\n    padding: 2%;\n}\n\n\n.arrow-icon {\n    transition: transform 0.5s;\n    width: 100%;\n    height: 16px;\n\n    \n}\n\n\n.options-list {\n    list-style-type: none;\n    margin: 0;\n    padding: 0;\n    position: absolute;\n    top: 120%;\n    left: -6px;\n    width: 100%;\n    background: #303030;\n    border-top: none;\n    border-radius: 0 0 4px 4px;\n    max-height: 440px;\n    overflow-y: auto;\n    color: #dfdfdf;;\n}\n\n/* Стили для опции */\n.options-list li {\n    padding: 8px;\n    cursor: pointer;\n    transition: background-color 0.5s;\n    \n}\n\n\n.options-list li:hover {\n    background-color: #1c1c1c;\n    color: #FFEA2D;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

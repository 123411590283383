 
import CardEda from './img/cardEda.png'


const CardInfo = [

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Ассорти мясных деликатесов',
        Gram: '230',
        Dish_Info: 'Буженина, Язык говяжий, Бекон, Ветчина, Колбаса, Помидоры, Маслины',
        Dish_price: '420'

    }, 

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Ассорти мясных деликатесов',
        Gram: '230',
        Dish_Info: 'Буженина, Язык говяжий, Бекон, Ветчина, Колбаса, Помидоры, Маслины',
        Dish_price: '420'

    }, 

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Ассорти мясных деликатесов',
        Gram: '230',
        Dish_Info: 'Буженина, Язык говяжий, Бекон, Ветчина, Колбаса, Помидоры, Маслины',
        Dish_price: '420'

    }, 

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Ассорти мясных деликатесов',
        Gram: '230',
        Dish_Info: 'Буженина, Язык говяжий, Бекон, Ветчина, Колбаса, Помидоры, Маслины',
        Dish_price: '420'

    }, 

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Ассорти мясных деликатесов',
        Gram: '230',
        Dish_Info: 'Буженина, Язык говяжий, Бекон, Ветчина, Колбаса, Помидоры, Маслины',
        Dish_price: '420'

    }, 



]

export default CardInfo
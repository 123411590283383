

export const MenuJson = [
    
    {

        deyweek: 'Понедельник' ,
        text1: 'Солянка' ,   
        text2: 'Сельдь под шубой' ,   
        text3: 'Азу по-Татарски' ,   
        text4: 'Картофельное пюре' ,   
        text5: 'Морс/Компот' ,   

    },

    {

        deyweek: 'Вторник' ,
        text1: 'Борщ с говядиной' ,   
        text2: 'Винегрет' ,   
        text3: 'Печень куриная' ,   
        text4: 'Гречка' ,   
        text5: 'Морс/Компот' ,   

    },

    {

        deyweek: 'Среда' ,
        text1: 'Лагман' ,   
        text2: 'Витаминный' ,   
        text3: 'Азу по-Татарски' ,   
        text4: 'Куриные крылья' ,   
        text5: 'Морс/Компот' ,   

    },

    {

        deyweek: 'Четверг' ,
        text1: 'Суп грибной' ,   
        text2: 'Крабовый' ,   
        text3: 'Рыба запечёная' ,   
        text4: 'Картофель запечёный' ,   
        text5: 'Морс/Компот' ,   

    },

    {

        deyweek: 'Пятница' ,
        text1: 'Суп куриный' ,   
        text2: 'Оливье' ,   
        text3: 'Котлета домашняя' ,   
        text4: 'Макароны' ,   
        text5: 'Морс/Компот' ,   

    },

    {

        deyweek: 'Суббота' ,
        text1: 'Суп чечевичный' ,   
        text2: 'Морковь по-корейски' ,   
        text3: 'Тефтели говяжьи' ,   
        text4: 'Овощная смесь' ,   
        text5: 'Морс/Компот' ,   

    },

    {

        deyweek: 'Воскресение' ,
        text1: 'Крем-суп овощной' ,   
        text2: 'Свекольный салат' ,
        text3: 'Запеканка картофельная с мясом' ,   
        text4: 'Овощная смесь' ,   
        text5: 'Морс/Компот' ,   

    },

]
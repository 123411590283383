 
import CardEda from './img/cardEda.svg'


const CardInfoKids = [

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    }, 

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    }, 

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },

    {
        id: 1 ,
        Img: CardEda ,
        Dish_Name : 'Бургер',
        Gram: '230',
        Dish_price: '120'

    },


]

export default CardInfoKids